import {
  LocationType,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { ServiceOptionType } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import {
  CalendarState,
  TFunction,
} from '../../components/BookingCalendar/controller';
import { CalendarContext } from '../context/contextFactory';
import {
  CalendarErrors,
  Optional,
  Preference,
  SelectedVariantOptions,
} from '../../types/types';
import { getSlotDuration } from '../duration/duration';
import {
  getFormattedPrice,
  isServiceVariantWithStaff,
} from '../dynamicPricing/dynamicPricing';

export const PreferencesErrors: CalendarErrors[] = [
  CalendarErrors.NO_SELECTED_LOCATION_ERROR,
  CalendarErrors.NO_SELECTED_DURATION_ERROR,
  CalendarErrors.NO_SELECTED_STAFF_MEMBER_ERROR,
  CalendarErrors.NO_SELECTED_CUSTOM_PREFERENCE_ERROR,
];

export type BookingsPreferenceError = {
  key: CalendarErrors;
  message: string;
};

export type SelectedBookingPreference = {
  key: Preference;
  value: string;
  numberOfParticipants?: number;
  isMultipleChoices?: boolean;
};

export type BookingPreferenceOption = {
  id?: string;
  value?: string;
  subtitle?: string;
  ariaLabel?: string;
  isSelectable?: boolean;
  numberOfParticipants?: number;
  isWithWaitingList?: boolean;
};

export type BookingPreference = {
  key: Preference;
  id?: string;
  error: BookingsPreferenceError;
  isMultipleChoices?: boolean;
  options: BookingPreferenceOption[];
  openSpotsRemained?: number;
  note?: string;
  disabled?: boolean;
  preselectedOptionId?: BookingPreferenceOption['id'];
  placeholder: string;
  getBookingPreferenceOptionFromSlot?: (
    slotAvailability: SlotAvailability,
  ) => BookingPreferenceOption;
  getBookingPreferenceOptionsFromSelectedVariantsOptions?: (
    selectedVariantsOptions: SelectedVariantOptions[],
  ) => BookingPreferenceOption[];
};

export const getBookingPreferences = ({
  context,
  state,
}: {
  context: CalendarContext;
  state: CalendarState;
}): BookingPreference[] => {
  const { getContent, t, businessInfo, settingsParams, experiments } = context;
  const { availableServices, serviceVariants, selectedTime } = state;

  const isDynamicPricingUoUEnabled = experiments.enabled(
    'specs.bookings.dynamicPricingUoU',
  );
  const isDynamicPricingCustomUoUEnabled = experiments.enabled(
    'specs.bookings.dynamicPricingCustomUoU',
  );

  const locationLabel = getContent({
    settingsParam: settingsParams.locationLabel,
    translationKey: 'app.settings.defaults.location-label',
  });
  const location: BookingPreference = {
    key: Preference.LOCATION,
    error: {
      key: CalendarErrors.NO_SELECTED_LOCATION_ERROR,
      message: t('app.booking-details.dropdowns.error.location.text'),
    },
    placeholder: locationLabel,
    options: [],
    getBookingPreferenceOptionFromSlot: (
      slotAvailability: SlotAvailability,
    ) => {
      const locationId = slotAvailability!.slot!.location!.id;
      const locationText = getLocationText(slotAvailability!.slot!.location, t);

      return {
        id: locationId || locationText!,
        value: locationText!,
      };
    },
  };

  const staffMemberLabel = getContent({
    settingsParam: settingsParams.staffMemberLabel,
    translationKey: 'app.settings.defaults.staff-member-label',
  });
  const staffMember: BookingPreference = {
    key: Preference.STAFF_MEMBER,
    error: {
      key: CalendarErrors.NO_SELECTED_STAFF_MEMBER_ERROR,
      message: t('app.booking-details.dropdowns.error.staff-member.text'),
    },
    placeholder: staffMemberLabel,
    options: [],
    getBookingPreferenceOptionFromSlot: (
      slotAvailability: SlotAvailability,
    ): BookingPreferenceOption => {
      const staffMemberName = slotAvailability.slot?.resource?.name!;
      const staffMemberId = slotAvailability.slot?.resource?.id!;
      let formattedPrice;
      const payment = availableServices[0].payment;
      if (
        isDynamicPricingUoUEnabled &&
        payment.paymentDetails.isVariedPricing &&
        serviceVariants?.options?.values?.length === 1 &&
        isServiceVariantWithStaff(serviceVariants)
      ) {
        const optionId = serviceVariants.options.values[0].id!;
        formattedPrice = getFormattedPrice({
          state,
          context,
          payment,
          choiceId: staffMemberId,
          optionId,
        });
      }
      return {
        id: staffMemberId,
        value: staffMemberName,
        subtitle: formattedPrice,
      };
    },
  };

  const durationLabel = getContent({
    settingsParam: settingsParams.durationLabel,
    translationKey: 'app.settings.defaults.duration-label',
  });
  const duration: BookingPreference = {
    key: Preference.DURATION,
    error: {
      key: CalendarErrors.NO_SELECTED_DURATION_ERROR,
      message: t('app.booking-details.dropdowns.error.duration.text'),
    },
    placeholder: durationLabel,
    options: [],
    getBookingPreferenceOptionFromSlot: (
      slotAvailability: SlotAvailability,
    ) => {
      const rfcStartTime = slotAvailability.slot?.startDate!;
      const rfcEndTime = slotAvailability.slot?.endDate!;
      const slotDuration = getSlotDuration({
        rfcStartTime,
        rfcEndTime,
        t,
        dateRegionalSettingsLocale: businessInfo!.dateRegionalSettingsLocale!,
      });

      return {
        id: slotDuration.durationText,
        value: slotDuration.durationText,
        ariaLabel: slotDuration.durationAriaText,
      };
    },
  };

  const customPreferences: BookingPreference[] = [];
  if (isDynamicPricingCustomUoUEnabled && serviceVariants && selectedTime) {
    const payment = availableServices[0].payment;

    const maxParticipantsPerBook =
      availableServices[0].policy.maxParticipantsPerBook;
    const isMultipleChoices = maxParticipantsPerBook > 1;

    serviceVariants.options?.values
      ?.filter(({ type }) => type === ServiceOptionType.CUSTOM)
      .forEach((option) => {
        customPreferences.push({
          key: Preference.CUSTOM,
          id: option.id!,
          placeholder: t(
            'app.booking-details.dropdowns.custom-preferences.label.text',
            {
              labelName: option.customData!.name,
            },
          ),
          error: {
            key: CalendarErrors.NO_SELECTED_CUSTOM_PREFERENCE_ERROR,
            message: t(
              'app.booking-details.dropdowns.error.custom-preference.text',
            ),
          },
          isMultipleChoices,
          getBookingPreferenceOptionsFromSelectedVariantsOptions: (
            selectedVariantsOptions,
          ) => {
            return (
              option.customData?.choices?.map((choice) => ({
                id: choice,
                value: choice,
                subtitle: getFormattedPrice({
                  state,
                  context,
                  optionId: option.id!,
                  choiceId: choice,
                  payment,
                }),
                numberOfParticipants:
                  selectedVariantsOptions.find(({ choices }) =>
                    choices.some(
                      ({ optionId, custom }) =>
                        optionId === option.id! && custom === choice,
                    ),
                  )?.numberOfParticipants || 0,
              })) || []
            );
          },
          options: [],
        });
      });
  }

  return [location, staffMember, duration, ...customPreferences];
};

export const getLocationText = (
  location: any,
  t: TFunction,
): Optional<string> => {
  switch (location?.locationType) {
    case LocationType.OWNER_BUSINESS:
      return location.name;
    case LocationType.OWNER_CUSTOM:
      return location.formattedAddress;
    case LocationType.CUSTOM:
      return t('app.booking-details.dropdowns.locations.client-place.text');
    default:
      return '';
  }
};
